<template>
  <div class="flex flex-row justify-center mt-6 text-xl font-semibold">
    {{ header }}
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: '',
    },
  },
};
</script>
