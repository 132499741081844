/* global axios */
import ApiClient from './ApiClient';

class Inboxes extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'inbox';
  }

  getCampaigns(inboxId) {
    return axios.get(`${this.url}/${inboxId}/campaigns`);
  }

  deleteInboxAvatar(inboxId) {
    return axios.delete(`${this.url}/${inboxId}/avatar`);
  }

  getAgentBot(inboxId) {
    return axios.get(`${this.url}/${inboxId}/agent_bot`);
  }

  setAgentBot(inboxId, botId) {
    return axios.post(`${this.url}/${inboxId}/set_agent_bot`, {
      agent_bot: botId,
    });
  }

  getWidgetStatus(inboxId) {
    return axios.post(`${this.url}/${inboxId}/widget_status`);
  }

  generatePresignedUrl(inboxId, url, path) {
    return axios.post(`${this.url}/${inboxId}/generate_presigned_url`, {
      url: url,
      file_path: path,
    });
  }

  generateDownloadPresignedUrl(inboxId, url) {
    return axios.post(
      `${this.url}/${inboxId}/generate_download_presigned_url`,
      {
        url: url,
      }
    );
  }

  deleteDocument(inboxId, botId, documentId) {
    return axios.delete(
      `${this.url}/${inboxId}/delete_document?bot_id=${botId}&document_number_id=${documentId}`
    );
  }

  getDocuments(inboxId, botId) {
    return axios.get(`${this.url}/${inboxId}/documents?bot_id=${botId}`);
  }

  getCorrections(inboxId, botId) {
    return axios.get(`${this.url}/${inboxId}/corrections?bot_id=${botId}`);
  }

  updateCorrection(inboxId, botId, correction) {
    return axios.post(`${this.url}/${inboxId}/update_correction`, {
      correction: correction,
      bot_id: botId
    });
  }

  createTextDocument(inboxId, url, documentId, text) {
    return axios.post(`${this.url}/${inboxId}/create_text_document`, {
      url: url,
      document_id: documentId,
      text: text,
    });
  }

  updateTextDocument(inboxId, id, documentId, bot_id, text) {
    return axios.put(`${this.url}/${inboxId}/update_text_document`, {
      document_id: documentId,
      text: text,
      bot_id: bot_id,
      document_number_id: Number(id),
    });
  }

  crawlWebsite(inboxId, botId, base_url) {
    return axios.post(`${this.url}/${inboxId}/crawl_website`, {
      base_url: base_url,
      bot_id: botId,
    });
  }

  getCrawlWebsiteProgress(inboxId, botId, base_url) {
    return axios.get(
      `${this.url}/${inboxId}/crawl_website_progress?bot_id=${botId}&base_url=${base_url}`
    );
  }
}

export default new Inboxes();
