<template>
  <div class="flex flex-col pb-24 bg-mila-main" role="container">
    <div class="w-[90%] mx-auto mt-4 rounded-lg">
      <div class="flex flex-col items-center py-2 mt-4">
        <thumbnail
          :src="currentUser.avatar_url"
          :username="currentUser.name"
          size="96px"
        />
        <p class="text-lg font-semibold">{{ currentUser.name }}</p>
      </div>
    </div>

    <div class="w-[90%] mx-auto mt-4 bg-white rounded-lg">
      <form @submit.prevent="updateUser('profile')">
        <div
          class="flex items-center justify-between px-3 py-4 mx-auto bg-gray-200 cursor-pointer rounded-t-md"
          @click="toggleAccordion('isProfileAccordionOpen')"
        >
          <span class="font-semibold">Profile Settings</span>
          <font-awesome-icon
            :icon="
              isProfileAccordionOpen
                ? 'fa-solid fa-chevron-up'
                : 'fa-solid fa-chevron-down'
            "
            class="text-sm transition-transform"
          />
        </div>

        <div v-if="isProfileAccordionOpen" class="px-3">
          <woot-avatar-uploader
            :label="$t('PROFILE_SETTINGS.FORM.PROFILE_IMAGE.LABEL')"
            :src="avatarUrl"
            @change="handleImageUpload"
          />

          <div
            v-if="showDeleteButton"
            class="flex flex-row justify-center px-3 avatar-delete-btn"
          >
            <woot-button
              type="button"
              color-scheme="alert"
              variant="hollow"
              size="small"
              @click="deleteAvatar"
            >
              {{ $t('PROFILE_SETTINGS.DELETE_AVATAR') }}
            </woot-button>
          </div>
          <label :class="{ error: $v.name.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model="name"
              type="text"
              :placeholder="$t('PROFILE_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @input="$v.name.$touch"
            />
            <span v-if="$v.name.$error" class="message">
              {{ $t('PROFILE_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.displayName.$error }">
            {{ $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.LABEL') }}
            <input
              v-model="displayName"
              type="text"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.DISPLAY_NAME.PLACEHOLDER')
              "
              @input="$v.displayName.$touch"
            />
          </label>
          <label
            v-if="!globalConfig.disableUserProfileUpdate"
            :class="{ error: $v.email.$error }"
          >
            {{ $t('PROFILE_SETTINGS.FORM.EMAIL.LABEL') }}
            <input
              v-model.trim="email"
              type="email"
              :placeholder="$t('PROFILE_SETTINGS.FORM.EMAIL.PLACEHOLDER')"
              @input="$v.email.$touch"
            />
            <span v-if="$v.email.$error" class="message">
              {{ $t('PROFILE_SETTINGS.FORM.EMAIL.ERROR') }}
            </span>
          </label>
          <div class="flex flex-row justify-center mb-4">
            <woot-button type="submit" :is-loading="isProfileUpdating">
              {{ $t('PROFILE_SETTINGS.BTN_TEXT') }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>

    <!-- Password Accordion -->
    <div class="w-[90%] mx-auto mt-4 bg-white rounded-lg">
      <div
        class="flex items-center justify-between px-3 py-4 mx-auto bg-gray-200 cursor-pointer rounded-t-md"
        @click="toggleAccordion('isPasswordAccordionOpen')"
      >
        <span class="font-semibold">Password Settings</span>
        <font-awesome-icon
          :icon="
            isPasswordAccordionOpen
              ? 'fa-solid fa-chevron-up'
              : 'fa-solid fa-chevron-down'
          "
          class="text-sm transition-transform"
        />
      </div>
      <div v-if="isPasswordAccordionOpen" class="px-3 pb-4">
        <form @submit.prevent="changePassword()">
          <woot-input
            v-model="currentPassword"
            type="password"
            :class="{ error: $v.currentPassword.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.LABEL')"
            :placeholder="
              $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.PLACEHOLDER')
            "
            :error="
              $v.currentPassword.$error
                ? $t('PROFILE_SETTINGS.FORM.CURRENT_PASSWORD.ERROR')
                : ''
            "
            @blur="$v.currentPassword.$touch"
          />
          <woot-input
            v-model="password"
            type="password"
            :class="{ error: $v.password.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.PASSWORD.LABEL')"
            :placeholder="$t('PROFILE_SETTINGS.FORM.PASSWORD.PLACEHOLDER')"
            :error="
              $v.password.$error
                ? $t('PROFILE_SETTINGS.FORM.PASSWORD.ERROR')
                : ''
            "
            @blur="$v.password.$touch"
          />

          <woot-input
            v-model="passwordConfirmation"
            type="password"
            :class="{ error: $v.passwordConfirmation.$error }"
            :label="$t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.LABEL')"
            :placeholder="
              $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.PLACEHOLDER')
            "
            :error="
              $v.passwordConfirmation.$error
                ? $t('PROFILE_SETTINGS.FORM.PASSWORD_CONFIRMATION.ERROR')
                : ''
            "
            @blur="$v.passwordConfirmation.$touch"
          />
          <div class="flex flex-row justify-center">
            <woot-button
              :is-loading="isPasswordChanging"
              type="submit"
              :disabled="
                !currentPassword ||
                !passwordConfirmation ||
                !$v.passwordConfirmation.isEqPassword
              "
            >
              {{ $t('PROFILE_SETTINGS.FORM.PASSWORD_SECTION.BTN_TEXT') }}
            </woot-button>
          </div>
        </form>
      </div>
    </div>

    <!-- Notification Accordion -->
    <div class="w-[90%] mx-auto mt-4 bg-white rounded-lg">
      <div
        class="flex items-center justify-between px-3 py-4 mx-auto bg-gray-200 cursor-pointer rounded-t-md"
        @click="toggleAccordion('isNotificationAccordionOpen')"
      >
        <span class="font-semibold">Notification Settings</span>
        <font-awesome-icon
          :icon="
            isNotificationAccordionOpen
              ? 'fa-solid fa-chevron-up'
              : 'fa-solid fa-chevron-down'
          "
          class="text-sm transition-transform"
        />
      </div>
      <div v-if="isNotificationAccordionOpen" class="px-3 pb-4">
        <h4 class="text-sm font-semibold">
          {{ $t('PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.TITLE') }}
        </h4>

        <!-- Alert type -->
        <span class="text-block-title notification-label">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.TITLE'
            )
          }}
        </span>
        <div>
          <input
            id="audio_enable_alert_none"
            v-model="enableAudioAlerts"
            class="notification--checkbox"
            type="radio"
            value="none"
            @input="handleAudioInput"
          />
          <label for="audio_enable_alert_none">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.NONE'
              )
            }}
          </label>
        </div>
        <div>
          <input
            id="audio_enable_alert_mine"
            v-model="enableAudioAlerts"
            class="notification--checkbox"
            type="radio"
            value="mine"
            @input="handleAudioInput"
          />
          <label for="audio_enable_alert_mine">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.UNREAD'
              )
            }}
          </label>
        </div>
        <div>
          <input
            id="audio_enable_alert_all"
            v-model="enableAudioAlerts"
            class="notification--checkbox"
            type="radio"
            value="all"
            @input="handleAudioInput"
          />
          <label for="audio_enable_alert_all">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.ALERT_TYPE.ALL_CONVERSATIONS'
              )
            }}
          </label>
        </div>

        <!-- Alert tone -->
        <span class="text-block-title notification-label">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.DEFAULT_TONE.TITLE'
            )
          }}
        </span>
        <div>
          <select
            v-model="notificationTone"
            class="tone-selector"
            @change="handleAudioToneChange"
          >
            <option
              v-for="tone in notificationAlertTones"
              :key="tone.value"
              :value="tone.value"
            >
              {{ tone.label }}
            </option>
          </select>
        </div>

        <!-- Alert Conditions -->
        <span class="text-block-title notification-label">
          {{
            $t(
              'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.TITLE'
            )
          }}
        </span>
        <div class="flex flex-row">
          <input
            id="audio_alert_when_tab_is_inactive"
            v-model="playAudioWhenTabIsInactive"
            class="notification--checkbox"
            type="checkbox"
            value="tab_is_inactive"
            @input="handleAudioAlertConditions"
          />
          <label for="audio_alert_when_tab_is_inactive">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_ONE'
              )
            }}
          </label>
        </div>
        <div class="flex flex-row">
          <input
            id="audio_alert_until_all_conversations_are_read"
            v-model="alertIfUnreadConversationExist"
            class="notification--checkbox"
            type="checkbox"
            value="conversations_are_read"
            @input="handleAudioAlertConditions"
          />
          <label for="audio_alert_until_all_conversations_are_read">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.AUDIO_NOTIFICATIONS_SECTION.CONDITIONS.CONDITION_TWO'
              )
            }}
          </label>
        </div>

        <!-- Emails -->
        <h4 class="mt-4 text-sm font-semibold">
          {{ $t('PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.TITLE') }}
        </h4>

        <div class="flex">
          <input
            id="conversation_creation"
            v-model="selectedEmailFlags"
            class="notification--checkbox"
            type="checkbox"
            value="email_conversation_creation"
            @input="handleEmailInput"
          />
          <label for="conversation_creation">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            id="conversation_status_changed_to_unread"
            v-model="selectedEmailFlags"
            class="notification--checkbox"
            type="checkbox"
            value="email_conversation_status_changed_to_unread"
            @input="handleEmailInput"
          />
          <label for="conversation_status_changed_to_unread">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_MOVED_TO_UNREAD'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            v-model="selectedEmailFlags"
            class="notification--checkbox"
            type="checkbox"
            value="email_conversation_assignment"
            @input="handleEmailInput"
            id="conversation_assignment"
          />
          <label for="conversation_assignment">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            id="conversation_review"
            v-model="selectedEmailFlags"
            class="notification--checkbox"
            type="checkbox"
            value="email_conversation_review"
            @input="handleEmailInput"
          />
          <label for="conversation_review">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.EMAIL_NOTIFICATIONS_SECTION.CONVERSATION_REVIEW'
              )
            }}
          </label>
        </div>

        <!-- Push -->

        <h4 class="mt-4 text-sm font-semibold">
          {{
            $t('PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.REQUEST_PUSH')
          }}
        </h4>

        <div class="flex">
          <input
            v-model="selectedPushFlags"
            class="notification--checkbox"
            type="checkbox"
            value="push_conversation_creation"
            @input="handlePushInput"
            id="conversation_creation"
          />
          <label for="conversation_creation">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_CREATION'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            v-model="selectedPushFlags"
            class="notification--checkbox"
            type="checkbox"
            value="push_conversation_status_changed_to_unread"
            @input="handlePushInput"
            id="conversation_status_changed_to_unread"
          />
          <label for="conversation_status_changed_to_unread">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_MOVED_TO_UNREAD'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            v-model="selectedPushFlags"
            class="notification--checkbox"
            type="checkbox"
            value="push_conversation_assignment"
            @input="handlePushInput"
            id="conversation_assignment"
          />
          <label for="conversation_assignment">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_ASSIGNMENT'
              )
            }}
          </label>
        </div>

        <div class="flex">
          <input
            v-model="selectedPushFlags"
            class="notification--checkbox"
            type="checkbox"
            value="push_conversation_review"
            @input="handlePushInput"
            id="conversation_review"
          />
          <label for="conversation_review">
            {{
              $t(
                'PROFILE_SETTINGS.FORM.PUSH_NOTIFICATIONS_SECTION.CONVERSATION_REVIEW'
              )
            }}
          </label>
        </div>
      </div>
    </div>

    <div
      class="px-4 py-4 w-[90%] mt-4 mx-auto rounded-lg btn-mila-outline text-center"
      @click="logout"
    >
      Logout
    </div>
  </div>
</template>

<script>
import { isMobile } from 'dashboard/mixins/uiSettings';
import { mapGetters } from 'vuex';
import Thumbnail from '../widgets/Thumbnail.vue';
import MobileHeader from 'dashboard/components/ui/MobileHeader.vue';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem.vue';
import { hasValidAvatarUrl } from 'dashboard/helper/URLHelper';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { clearCookiesOnLogout } from '../../store/utils/api';
import {
  hasPushPermissions,
  requestPushPermissions,
  verifyServiceWorkerExistence,
} from '../../helper/pushHelper';
import Auth from '../../api/auth';

export default {
  components: {
    Thumbnail,
    MobileHeader,
    AccordionItem,
  },
  data() {
    return {
      isProfileAccordionOpen: true,
      isPasswordAccordionOpen: true,
      isNotificationAccordionOpen: true,
      avatarFile: '',
      avatarUrl: '',
      name: '',
      displayName: '',
      email: '',
      isProfileUpdating: false,
      errorMessage: '',
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      isPasswordChanging: false,
      errorMessagePassword: '',

      selectedEmailFlags: [],
      selectedPushFlags: [],
      enableAudioAlerts: false,
      hasEnabledPushPermissions: false,
      playAudioWhenTabIsInactive: false,
      alertIfUnreadConversationExist: false,
      notificationTone: 'ding',
      notificationAlertTones: [
        {
          value: 'ding',
          label: 'Ding',
        },
        {
          value: 'bell',
          label: 'Bell',
        },
      ],
    };
  },
  props: {},
  mixins: [alertMixin, globalConfigMixin, uiSettingsMixin],
  computed: {
    mobile() {
      return isMobile();
    },
    showDeleteButton() {
      return hasValidAvatarUrl(this.avatarUrl);
    },
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      currentAccount: 'getCurrentAccount',
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      currentUserAvailability: 'getCurrentUserAvailability',
      globalConfig: 'globalConfig/get',
      emailFlags: 'userNotificationSettings/getSelectedEmailFlags',
      pushFlags: 'userNotificationSettings/getSelectedPushFlags',
      uiSettings: 'getUISettings',
      inboxesList: 'inboxes/getInboxes',
    }),
    statusOfAgent() {
      return this.currentUserAvailability || 'offline';
    },
    hasPushAPISupport() {
      return !!('Notification' in window);
    },
  },
  watch: {
    currentUserId(newCurrentUserId, prevCurrentUserId) {
      if (prevCurrentUserId !== newCurrentUserId) {
        this.initializeUser();
      }
    },
    inboxesList() {
        console.log("this.inboxesList", this.inboxesList)
    },
    uiSettings(value) {
      this.notificationUISettings(value);
    },
  },
  beforeMount() {},
  mounted() {
    if (this.currentUserId) {
      this.initializeUser();
    }
    if (hasPushPermissions()) {
      this.getPushSubscription();
    }
    this.notificationUISettings(this.uiSettings);
    this.$store.dispatch('userNotificationSettings/get');
  },
  validations: {
    name: {
      required,
      minLength: minLength(1),
    },
    displayName: {},
    email: {
      required,
      email,
    },
    currentPassword: {
      required,
    },
    password: {
      minLength: minLength(6),
    },
    passwordConfirmation: {
      minLength: minLength(6),
      isEqPassword(value) {
        if (value !== this.password) {
          return false;
        }
        return true;
      },
    },
  },
  methods: {
    logout() {
      Auth.logout();
      this.$posthog.reset();
    },
    notificationUISettings(uiSettings) {
      const {
        enable_audio_alerts: enableAudio = false,
        always_play_audio_alert: alwaysPlayAudioAlert,
        alert_if_unread_assigned_conversation_exist:
          alertIfUnreadConversationExist,
        notification_tone: notificationTone,
      } = uiSettings;
      this.enableAudioAlerts = enableAudio;
      this.playAudioWhenTabIsInactive = !alwaysPlayAudioAlert;
      this.alertIfUnreadConversationExist = alertIfUnreadConversationExist;
      this.notificationTone = notificationTone || 'ding';
    },
    async updateNotificationSettings() {
      try {
        this.$store.dispatch('userNotificationSettings/update', {
          selectedEmailFlags: this.selectedEmailFlags,
          selectedPushFlags: this.selectedPushFlags,
        });
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_ERROR'));
      }
    },
    handleEmailInput(e) {
      this.selectedEmailFlags = this.toggleInput(
        this.selectedEmailFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handlePushInput(e) {
      this.selectedPushFlags = this.toggleInput(
        this.selectedPushFlags,
        e.target.value
      );

      this.updateNotificationSettings();
    },
    handleAudioInput(e) {
      this.enableAudioAlerts = e.target.value;
      this.updateUISettings({
        enable_audio_alerts: this.enableAudioAlerts,
      });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioAlertConditions(e) {
      let condition = e.target.value;
      if (condition === 'tab_is_inactive') {
        this.updateUISettings({
          always_play_audio_alert: !e.target.checked,
        });
      } else if (condition === 'conversations_are_read') {
        this.updateUISettings({
          alert_if_unread_assigned_conversation_exist: e.target.checked,
        });
      }
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    handleAudioToneChange(e) {
      this.updateUISettings({ notification_tone: e.target.value });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        const newSelectedFlags = selected.filter(flag => flag !== current);
        return newSelectedFlags;
      }
      return [...selected, current];
    },
    onRegistrationSuccess() {
      this.hasEnabledPushPermissions = true;
    },
    onRequestPermissions() {
      requestPushPermissions({
        onSuccess: this.onRegistrationSuccess,
      });
    },
    initializeUser() {
      this.name = this.currentUser.name;
      this.email = this.currentUser.email;
      this.avatarUrl = this.currentUser.avatar_url;
      this.displayName = this.currentUser.display_name;
    },
    async changePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      try {
        await this.$store.dispatch('updateProfile', {
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          current_password: this.currentPassword,
        });
        this.errorMessagePassword = this.$t(
          'PROFILE_SETTINGS.PASSWORD_UPDATE_SUCCESS'
        );
      } catch (error) {
        this.errorMessagePassword = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.message) {
          this.errorMessagePassword = error.response.data.message;
        }
      } finally {
        this.isPasswordChanging = false;
        this.showAlert(this.errorMessagePassword);
      }
    },
    async updateUser() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }

      this.isProfileUpdating = true;
      const hasEmailChanged = this.currentUser.email !== this.email;
      try {
        await this.$store.dispatch('updateProfile', {
          name: this.name,
          email: this.email,
          avatar: this.avatarFile,
          displayName: this.displayName,
        });
        this.isProfileUpdating = false;
        if (hasEmailChanged) {
          clearCookiesOnLogout();
          this.errorMessage = this.$t('PROFILE_SETTINGS.AFTER_EMAIL_CHANGED');
        }
        this.errorMessage = this.$t('PROFILE_SETTINGS.UPDATE_SUCCESS');
      } catch (error) {
        this.errorMessage = this.$t('RESET_PASSWORD.API.ERROR_MESSAGE');
        if (error?.response?.data?.error) {
          this.errorMessage = error.response.data.error;
        }
      } finally {
        this.isProfileUpdating = false;
        this.showAlert(this.errorMessage);
      }
    },
    toggleAccordion(name) {
      this[name] = !this[name];
    },
    handleImageUpload({ file, url }) {
      this.avatarFile = file;
      this.avatarUrl = url;
    },
    async deleteAvatar() {
      try {
        await this.$store.dispatch('deleteAvatar');
        this.avatarUrl = '';
        this.avatarFile = '';
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_DELETE_FAILED'));
      }
    },
    handleAudioInput(e) {
      this.enableAudioAlerts = e.target.value;
      this.updateUISettings({
        enable_audio_alerts: this.enableAudioAlerts,
      });
      this.showAlert(this.$t('PROFILE_SETTINGS.FORM.API.UPDATE_SUCCESS'));
    },
    getPushSubscription() {
      verifyServiceWorkerExistence(registration =>
        registration.pushManager
          .getSubscription()
          .then(subscription => {
            if (!subscription) {
              this.hasEnabledPushPermissions = false;
            } else {
              this.hasEnabledPushPermissions = true;
            }
          })
          // eslint-disable-next-line no-console
          .catch(error => console.log(error))
      );
    },
  },
};
</script>

<style></style>
