import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = accountId => [ //disabled is for mobile only
  {
    icon: 'comment-add',
    key: 'new_inb',
    label: 'SETUP',
    toState: frontendURL(`accounts/${accountId}/new-inbox`),
    toStateName: 'new_inb',
    roles: ['administrator', 'agent'],
    disabled: false
  },
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent'],
    disabled: false
  },
  {
    icon: 'book-contacts',
    key: 'my-content',
    label: 'MY_CONTENT',
    toState: frontendURL(`accounts/${accountId}/my-content`),
    toStateName: 'my_content',
    roles: ['administrator', 'agent'],
    disabled: false
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'settings_account_campaigns',
    roles: [''],
    disabled: true,
  },
  {
    icon: 'library',
    key: 'helpcenter',
    label: 'HELP_CENTER.TITLE',
    featureFlag: FEATURE_FLAGS.HELP_CENTER,
    alwaysVisibleOnChatwootInstances: true,
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'default_portal_articles',
    roles: ['super_administrator'],
    disabled: true
  },
  {
    icon: 'autocorrect',
    key: 'corrections',
    label: 'CORRECTIONS',
    toState: frontendURL(`accounts/${accountId}/corrections`),
    toStateName: 'corrections',
    roles: ['administrator', 'agent'],
    disabled: true
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings/inboxes/list`),
    toStateName: 'settings_home',
    roles: ['administrator', 'agent'],
    disabled: true
  },
  {
    icon: 'settings',
    key: 'settings_mobile',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings/mobile`),
    toStateName: 'settings_mobile',
    roles: ['administrator', 'agent'],
    disabled: false,
    mobile_only: true
  },
];

export default primaryMenuItems;
